// Defaults
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import reportService from "../../utils/api/v1/reportService";
import * as moment from "moment";

// Typings
import { Laboratory } from "../../utils/interfaces/Laboratory";

// Components
import { Button, Select } from "@material-ui/core";
import Flex from "../Containers/Flex";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { MenuItem } from "@material-ui/core";

moment.locale("es");

const Spaced = styled.div`
  margin: 0.5rem;
`;

interface FonasaReportProps {
  setError: (err: Object) => void;
  labs: Laboratory[];
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

const FonasaReport = (props: FonasaReportProps): JSX.Element => {
  const [reportDate, setReportDate] = useState<moment.Moment>(moment());
  const [selectedLabRut, setSelectedLabRut] = useState<string>("");

  const downloadFonasaZip = async () => {
    props.setLoading(true);
    try {
      const res = await reportService.fetchFonasaReport(
        reportDate.format("YYYY-MM-DD"),
        selectedLabRut
      );
      const link = document.createElement("a");
      const url = window.URL.createObjectURL(new Blob([res.data]));
      link.href = url;
      link.setAttribute("download", "bonos.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.log(err);
      props.setError(err);
    }
    props.setLoading(false);
  };

  useEffect(() => {
    if (props.labs.length > 0) {
      setSelectedLabRut(props.labs[0].document_number);
    }
  }, [props.labs]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedLabRut(event.target.value as string);
  };

  return (
    <>
      <h2>Reporte Bonos Fonasa</h2>
      <Flex align="center" justify="space-around">
        <Spaced>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              variant="inline"
              format="LL"
              margin="normal"
              label="Fecha inicio"
              value={reportDate}
              autoOk
              onChange={(date: moment.Moment) => {
                setReportDate(date);
              }}
            />
          </MuiPickersUtilsProvider>
        </Spaced>
        <Spaced>
          <Select
            value={selectedLabRut}
            label="Laboratorio"
            onChange={handleChange}
            fullWidth
          >
            {props.labs.map((lab: Laboratory) => {
              return (
                <MenuItem key={lab.id} value={lab.document_number}>
                  {lab.display_name}
                </MenuItem>
              );
            })}
          </Select>
        </Spaced>
        <Spaced>
          <Button variant="outlined" color="primary" disabled={props.loading} onClick={downloadFonasaZip}>
            Generar reporte
          </Button>
        </Spaced>
      </Flex>
    </>
  );
};

export default FonasaReport;
