import api from "../api";

const reportsBaseUrl = 'analytics/reports';
class ReportService {
  fetchFonasaReport = (date: string, labRut: string) => {
    return api.get(`${reportsBaseUrl}/fonasa`, { params: { date, lab_rut: labRut }, responseType: 'blob' });
  }
}

const reportService = new ReportService();

export default reportService;
