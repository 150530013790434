import React, { useEffect, useState } from "react";
import styled from "styled-components";
import labService from "../../utils/api/v1/labService";

//Typings
import { Laboratory } from "../../utils/interfaces/Laboratory";

//Components
import PrivateRoute from "../../components/Authentication/PrivateRoute";
import InnerContainer from "../../components/Containers/InnerContainer";
import LoadingError from "../../components/Loaders/LoadingError";
import FonasaReport from "../../components/Reports/FonasaReport";

const ReportsTitle = styled.h1`
  margin: 2rem 0rem;
  border-bottom: 1px solid #ccc;
`;

const ReportIndex = (): JSX.Element => {
  const [error, setError] = useState<Object>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [labs, setLabs] = useState<Laboratory[]>([]);

  useEffect(() => {
    const fetchLabs = async () => {
      setLoading(true);
      try {
        const res = await labService.fetchLabs(1000);
        setLabs(res.data.results);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };
    fetchLabs();
  }, []);


  return (
    <PrivateRoute>
      <InnerContainer>
        <LoadingError loading={loading} error={error as any} />
        {!loading && (
          <>
            <ReportsTitle>Reporte fonasa</ReportsTitle>
            <FonasaReport
              setError={(err: Object) => {
                setError(err);
              }}
              labs={labs}
              loading={loading}
              setLoading={setLoading}
            />
          </>
        )}
      </InnerContainer>
    </PrivateRoute>
  );
};

export default ReportIndex;
